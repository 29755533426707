<template>
  <div>
    <transition name="el-zoom-in-center">
      <div class="formBox" v-if="formShow">
        <div class="formTitleBox">
          <div class="leftBack">
            <div class="backBox" @click="formShow = false">
              <i class="el-icon-back"></i>
              返回
            </div>
            <span style="font-size: 20px; margin-left: 40px">{{
                formTitle
              }}</span>
          </div>
          <div class="rightBtn">
            <el-button-group>
              <el-button size="mini" @click="submitAllForm(1)" v-if="formType != 'view'">保存</el-button>
              <el-button size="mini" @click="submitAllForm(2)" v-if="formType != 'view'">保存并返回</el-button>
              <el-button size="mini" @click="closeForm">取消</el-button>
            </el-button-group>
          </div>
        </div>
        <div class="formContentBox">
          <div class="formMain">
            <div>
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm" label-width="150px" :rules="rules">
                  <div class="formTopic" style="margin-bottom: 12px">基本信息</div>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="任务编码" prop="taskCode">
                        <el-input v-model="dataForm.taskCode" disabled placeholder="系统自动生成"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="任务名称" prop="taskName">
                        <el-input v-model="dataForm.taskName" placeholder="请输入任务名称"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="状态" prop="acquisitionStatus">
                        <el-select v-model="dataForm.acquisitionStatus" placeholder="请选择状态" disabled>
                          <el-option
                            v-for="item in publishStatusOption"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="编制人" prop="weaveUser">
                        <el-input v-model="dataForm.weaveUser" disabled placeholder="系统自动生成"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="编制单位" prop="weaveDept">
                        <el-input v-model="dataForm.weaveDept" disabled placeholder="系统自动生成"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="模板编码" prop="templateCode">
                        <el-select v-model="dataForm.templateCode" placeholder="请选择模板编码" clearable @change="chooseTemplate">
                          <el-option
                            v-for="item in templateOptions"
                            :key="item.templateCode"
                            :label="item.templateName"
                            :value="item.templateCode">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div class="formTopic" style="margin-bottom: 12px">填报要求</div>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="标题：" prop="title">
                        <el-input v-model="dataForm.title" type="title" placeholder="请输入标题">
                        </el-input>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="角色：" prop="roleId">
                        <el-select v-model="dataForm.roleId" placeholder="请选择角色" @change="chooseRole">
                          <el-option
                            v-for="item in roleData"
                            :key="item.id"
                            :label="item.roleName"
                            :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="填报截至日期：" prop="deadlineFillingDate">
                        <el-date-picker
                          v-model="dataForm.deadlineFillingDate"
                          type="date"
                          format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请选择采集时间"
                        >
                        </el-date-picker>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="汇总截至日期：" prop="deadlineSummaryDate">
                        <el-date-picker
                          v-model="dataForm.deadlineSummaryDate"
                          type="date"
                          format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请选择采集时间"
                        >
                        </el-date-picker>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="上传附件：">
                        <el-upload
                          class="upload-demo"
                          action="api/sinoma-resource/oss/endpoint/put-file"
                          :on-success="handleSuccess"
                          :on-remove="handleRemove"
                          :headers="headers"
                          multiple
                          :limit="1"
                          :file-list="fileList"
                        >
                          <el-button size="small" type="primary"
                          >点击上传
                          </el-button
                          >
                          <div slot="tip" class="el-upload__tip">
                            支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                          </div>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item label="填写说明：">
                        <el-input v-model="dataForm.acquisitionDescription" type="textarea">
                        </el-input>
                      </el-form-item
                      >
                    </el-col>
                  </el-row>
                </el-form>
                <div class="formTopic">填报单位</div>
                <div
                  style="padding: 12px; display: flex; justify-content: center"
                >
                  <el-radio-group v-model="groupRadio" @input="chooseGroup">
                    <el-radio :label="item.id" border v-for="(item,index) in groupOption" :key="index">{{item.groupName}}</el-radio>
                  </el-radio-group>
                </div>
                <div class="shuttleBackBox">
                  <div class="shutleBack">
                    <div class="shutleTitle">组织架构</div>
                    <div class="shutContent">
                      <el-tree
                        ref="tree"
                        :data="deptTree"
                        :props="defaultProps"
                        node-key="id"
                        @node-click="handleNodeClick"
                      ></el-tree>
                    </div>
                  </div>
                  <div class="leftRightBtn">
                    <el-button-group>
                      <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-arrow-left"
                      ></el-button>
                      <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-arrow-right"
                      ></el-button>
                    </el-button-group>
                  </div>
                  <div class="shutleBack">
                    <div class="shutleTitle">填报单位</div>
                    <div class="shutContent">
                      <div
                        class="deptBox"
                        v-for="(item, index) in middleShutData"
                        :key="index"
                      >
                        <el-tooltip effect="dark" :content="item.organize " placement="top-start">
                          <span>{{ item.organize }}</span>
                        </el-tooltip>
                        <i
                          class="el-icon-delete"
                          style="
                          color: #f56c6c;
                          font-size: 20px;
                          line-height: unset;
                          cursor: pointer;
                        "
                          @click="deleteMiddleShut(item, index)"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="leftRightBtn">
                    <el-button-group>
                      <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-arrow-left"
                      ></el-button>
                      <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-arrow-right"
                      ></el-button>
                    </el-button-group>
                  </div>
                  <div class="shutleBack">
                    <div class="shutleTitle">汇总单位</div>
                    <div class="shutContent">
                      <div
                        class="deptBox"
                        v-for="(item, index) in rightShutData"
                        :key="index"
                      >
                        <el-tooltip effect="dark" :content="item.organize " placement="top-start">
                          <span>{{ item.organize }}</span>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import {dateFormat} from "@/util/date";
import {attributeDetail, attributeSave, dictionaryBiz, dictionary, templateByEnable} from "@/api/reportTasks";
import {mapGetters} from "vuex";
import {getUserInfo} from "@/api/system/user";
import {DeptGroupDetail, GettrainingDeptGroup} from "@/api/safe";

export default {
  data() {
    return {
      formShow: false,
      formTitle: '',
      groupRadio:'',
      dataForm: {
        id: '',
        taskCode:'',
        taskName:'',
        acquisitionStatus:'1',
        weaveUser:'',
        weaveDept:'',
        templateCode:'',
        title:'',
        tableName:'',
        roleId:'',
        acquisitionCycle:'',
        acquisitionTime:'',
        reportingPeriod:'',
        summaryPeriod:'',
        acquisitionDescription:'',
        isCycle:'2',
        deadlineFillingDate:'',
        deadlineSummaryDate:'',
        summaryList:[],
        fillList:[]
      },
      defaultProps: {
        children: "children",
        label: "fullName",
      },
      rules:{
        taskName: [
          {required: true, message: '请输入任务名称', trigger: 'blur'}
        ],
        acquisitionStatus: [
          {required: true, message: '请选择状态', trigger: 'change'}
        ],
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'}
        ],
        roleId: [
          {required: true, message: '请选择角色', trigger: 'change'}
        ],
        templateCode: [
          {required: true, message: '请选择模板编码', trigger: 'blur'}
        ],
        deadlineFillingDate: [
          {required: true, message: '请选择填报截至日期', trigger: 'change'}
        ],
        deadlineSummaryDate: [
          {required: true, message: '请选择汇总截至日期', trigger: 'change'}
        ]
      },
      fnDeptTree: [],
      middleShutData: [],
      rightShutData: [],
      templateOptions:[],
      publishStatusOption: [],
      groupOption:[]
    }
  },
  computed: {
    ...mapGetters(["deptTree", "roleData", "userInfo"]),
  },
  mounted() {
    this.fnDeptTree = this.fnTree(this.deptTree);
    this.headers = {
      "Sinoma-Auth": "bearer " + this.userInfo.access_token,
    };
    this.getDictory();
  },
  methods: {
    chooseTemplate(e) {
      let data = this.templateOptions.filter((item) => {
        return e == item.templateCode
      })
      this.dataForm.tableName = data[0].tableName;
    },
    // 选择常用分组
    chooseGroup(e) {
      DeptGroupDetail({id:e}).then((res) =>{
        this.middleShutData = [];
        this.rightShutData = [];
        let list = new Array();
        let deptIdList = res.data.data.deptIdList;
        deptIdList.forEach((item) =>{
          let data = this.fnDeptTree.filter((i) => {
            return i.id == item
          })
          list.push(data[0])
        })
        this.middleShutData = list;
        this.countUnit();
      })
    },
    resetDataForm() {
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields();
      })
      this.middleShutData = [];
      this.rightShutData = [];
      this.fileList = [];
      this.groupRadio = '';
      this.dataForm = {
        id: '',
        taskCode:'',
        taskName:'',
        acquisitionStatus:'1',
        weaveUser:'',
        weaveDept:'',
        templateCode:'',
        title:'',
        tableName:'',
        roleId:'',
        acquisitionCycle:'',
        acquisitionTime:'',
        reportingPeriod:'',
        summaryPeriod:'',
        acquisitionDescription:'',
        isCycle:'2',
        deadlineFillingDate:'',
        deadlineSummaryDate:'',
        summaryList:[],
      }
    },
    getDictory() {
      dictionaryBiz("report_data_type").then((res) => {
        this.typeOptions = res.data.data;
      });
      dictionary("yes_no").then((res) => {
        this.yesOrNoOptions = res.data.data;
      });
      dictionaryBiz("report_input_way").then((res) => {
        this.inputWayOptions = res.data.data;
      });
      templateByEnable(this.userInfo.dept_id).then((res) => {
        this.templateOptions = res.data.data
      })
      dictionaryBiz("publish_status").then((res) => {
        this.publishStatusOption = res.data.data;
      });
      GettrainingDeptGroup({
        current: 1,
        size: 50,
      }).then((res) => {
        this.groupOption = res.data.data.records;
      })
    },
    getNowUserInfo() {
      getUserInfo().then((res) => {
        this.dataForm.weaveUser = res.data.data.roleName;
        this.dataForm.weaveDept = res.data.data.deptName;
      })
    },
    closeForm() {
      this.formShow = false;
      this.$parent.onLoad(this.$parent.page);
    },
    // 保存填报设置
    submitAllForm(number) {
      // if (this.dataForm.acquisitionCycle == 1 || this.dataForm.acquisitionCycle == 5) {
      //   this.dataForm.acquisitionTime = dateFormat(new Date(), "yyyy-MM-dd") + ' 00:00:00'
      // }
      if (this.middleShutData.length == 0) {
        this.$message({
          message: "请选择填报单位",
          type: "warning",
        });
        return;
      }
      this.dataForm.fillList = this.middleShutData;
      this.dataForm.summaryList = this.rightShutData;
      attributeSave(this.dataForm).then((res) => {
        if (res.data.code == 200) {
          if (number == 2) {
            this.formShow = false;
            this.$parent.onLoad(this.$parent.page);
          } else {
            this.dataForm = res.data.data.acquisitionAttribute;
            this.rightShutData = res.data.data.acquisitionAttribute.summaryList;
            let middleData = new Array();
            let middleShutData = res.data.data.acquisitionAttribute.fillList;
            middleShutData.forEach((item) => {
              let data = this.fnDeptTree.filter((i) => {
                return item.unitId == i.id
              })
              middleData.push(data[0]);
            })
            this.middleShutData = middleData;
          }
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      })
    },
    // 根据ID查表单详情
    getAttributeDetail() {
      attributeDetail(this.dataForm.id).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data.acquisitionAttribute;
          this.dataForm.acquisitionStatus = res.data.data.acquisitionAttribute.acquisitionStatus.toString();
          if (res.data.data.acquisitionAttribute.attachDescription != '') {
            this.fileList = JSON.parse(res.data.data.acquisitionAttribute.attachDescription)
          }
          // if (this.dataForm.acquisitionCycle == 4) {
          //   let timeData = this.dataForm.acquisitionTime.split(',');
          //   this.month = timeData[0];
          //   this.day = timeData[1];
          // }
          this.rightShutData = res.data.data.acquisitionAttribute.summaryList;
          let middleShutData = res.data.data.acquisitionAttribute.fillList;
          middleShutData.forEach((item) => {
            let data = this.fnDeptTree.filter((i) => {
              return item.unitId == i.id
            })
            this.middleShutData.push(data[0]);
          })
        }
      })
    },
    // 选择角色查出角色名
    chooseRole(e) {
      let data = this.roleData.filter((item) => {
        return item.id == e
      })
      this.dataForm.roleName = data[0].roleName;
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link
          });
        } else {
          list.push({
            name: i.name,
            url: i.url
          });
        }
      })
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let list = new Array();
      fileList.map((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link
          });
        } else {
          list.push({
            name: i.name,
            url: i.url
          });
        }
      })
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 选择填报单位查出汇总单位
    handleNodeClick(node) {
      let middleFilter = this.middleShutData.filter((item) => {
        return item.id == node.id;
      });
      if (middleFilter.length != 0) return;
      this.middleShutData.push(node);
      this.countUnit();
    },
    // 删除填报单位
    deleteMiddleShut(item, index) {
      this.middleShutData.splice(index, 1);
      this.countUnit();
    },
    // 计算填报单位
    countUnit() {
      let rightName = new Array();
      let rightIds = new Array();
      let middleIds = new Array();
      this.middleShutData.forEach((item) => {
        item.organizeIds.forEach((i) => {
          rightIds.push(i);
        });
        middleIds.push(item.id);
      });
      rightIds = [...new Set(rightIds)];
      this.rightShutIds = this.getArrDifference(middleIds, rightIds);
      this.middleShutData.forEach((item) => {
        if (item.hasChildren == true) {
          this.rightShutIds.push(item.id)
        }
      })
      this.rightShutIds.forEach((item) => {
        let data = this.fnDeptTree.filter((i) => {
          return i.id == item
        })
        rightName.push(data[0])
      })
      this.rightShutData = rightName;
    },
    // 数组取插值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (value, i, arr) {
        return arr.indexOf(value) === arr.lastIndexOf(value);
      });
    },
    fnTree(arr) {
      let data = JSON.parse(JSON.stringify(arr));
      let newData = [];
      const callback = (item) => {
        (item.children || (item.children = [])).map((v) => {
          callback(v);
        });
        delete item.children;
        newData.push(item);
      };
      data.map((v) => callback(v));
      return newData;
    },
  }
}
</script>
<style lang="scss" scoped>
.formBox {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.formTitleBox {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.leftBack {
  cursor: pointer;
  display: flex;
}

.backBox {
  position: relative;
  line-height: 28px;
}

.backBox:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dcdfe6;
}

.rightBtn {
  display: flex;
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.shutleBack {
  width: 27%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.shutImg {
  width: 40px;
  height: 40px;
  display: block;
  margin: 207px 12px;
}

.deptBox {
  height: 40px;
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  justify-content: space-between;
  display: flex;
}

.deptBox span {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
