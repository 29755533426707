<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.dataDictionary.title.name')"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">

    </head-layout>
    <!-- <el-container> -->
      <!-- <el-header > -->
        <div class="headers" style="display: flex;">
          <el-input :placeholder="$t('cip.dc.dataDictionary.field.code')" v-model="queryList.dict_code" class="new-sino-input" size="mini" style="width:200px" />
          <el-input :placeholder="$t('cip.dc.dataDictionary.field.name')" v-model="queryList.dict_name" class="new-sino-input" style="width:200px" size="mini" />

          <el-button-group style="margin-left: 10px;">
            <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
          </el-button-group>

        </div>


      <!-- </el-header> -->

      <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="handleSelectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-edit="handleEdit"
            @grid-romve="deleteFn"
            :searchColumns="searchColumns"
          >

          </grid-layout>



  </div>
</template>

<script>
import {
  getDictList,
  delDict
} from "@/api/dataAcquisition/DataAssets";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        }
      ],
        tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.dc.dataDictionary.field.FieldCode'),
            prop: "dict_code",
            cell: true,

          },
          {
            label: this.$t('cip.dc.dataDictionary.field.DictionaryName'),
            prop: "dict_name",
            cell: true,

          },
        ],

      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableLoading:false,
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        perPage:20,
        startIndex:1,
        dict_code:'',
        dict_name:''
      },
      loading:false,
      rules: {
        sourceCode: [
          { required: true, message: this.$t('cip.dc.dataDictionary.rules.rules1'), trigger: 'change' }
        ],
        sourceName: [
          { required: true, message: this.$t('cip.dc.dataDictionary.rules.rules1'), trigger: 'change' }
        ],
        sourceDesc: [
          { required: true, message: this.$t('cip.dc.dataDictionary.rules.rules1'), trigger: 'change' }
        ],
        sourceType: [
          { required: true, message:this.$t('cip.dc.dataDictionary.rules.rules2'), trigger: 'change' }
        ],
        databaseName: [
          { required: true, message: this.$t('cip.dc.dataDictionary.rules.rules3'), trigger: 'change' }
        ],
        sourceConfig: [
          { required: true, message: this.$t('cip.dc.dataDictionary.rules.rules4'), trigger: 'change' }
        ],
        type: [
          { required: true, message: this.$t('cip.dc.dataDictionary.rules.rules5'), trigger: 'change' }
        ],
      },
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      getDictList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.list
        this.total = obj.total
      });
    },
    addFn(){
      this.$router.push({
        path: '/DataAssets/dataDictionaryAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/DataAssets/dataDictionaryAdd',
        query: {
          type: 'edit',
          id:item.dict_id
        }
      })
    },
    deleteFn(item){
      if(item) this.multipleSelection = [item]
      if(this.multipleSelection.length == 0){
        this.$message.error(this.$t('cip.dc.dataDictionary.msg.msg1'))
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.dict_id
      })
      this.$confirm(this.$t('cip.dc.dataDictionary.msg.msg1'), this.$t('cip.dc.dataDictionary.msg.Tips'), {
        confirmButtonText: this.$t('projectlist.btn.sure'),
        cancelButtonText: this.$t('projectlist.btn.close'),
        type: 'warning'
      }).then(() => {
        delDict(ids).then((res)=>{
          this.$message.success(this.$t('cip.dc.dataDictionary.msg.msg2'));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList = {
        perPage:20,
        startIndex:1,
        dict_code:'',
        dict_name:''
      }
      this.page.currentPage = 1
      this.page.pageSize = 20
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      this.queryList.perPage = val.pageSize;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.startIndex = val.currentPage;
      this.onLoad()
    },
  },
  created() {
    this.onLoad()
  },
  mounted() {
  },
};
</script>
<style scoped>
.headers{
  background: #FFFFFF;
  padding: 14px 12px;
}
.headers ::v-deep .el-button{
  height: 28px;
}
</style>
<style>
.el-button+.el-button {
  margin-left: 0px;
}
</style>
